export default {
  data() {
    return {
      courseStatusConstant: {
        AVAILABLE: 1,
        EDITING: 2,
      },

    }
  }
}